import React from "react";
import mainImg from "../../assets/icons/herroimg.jpg";
import "./Process.css";
import { process } from "../../assets/jsons/processData";

function Process() {
  return (
    <div className="processContainer">
      <div className="left-p">
        <h1 className="mainHeading">We provide the best process experience</h1>
        <div className="cardsHolder">
          {/*  */}
          {process.map((process, index) => (
            <div className="card">
              <div className="count">
                <h3>
                  <span>{process.id}</span>
                </h3>
              </div>
              <div className="text">
                <span>{process.title}</span>
                <span>{process.text}</span>
              </div>
            </div>
          ))}

          {/*  */}
        </div>
      </div>
      <div className="right-p">
        <img src={mainImg} />
      </div>
    </div>
  );
}

export default Process;
