import React from "react";
import "./Experience.css";
import mainImg from "../../assets/icons/herroimg.jpg";
import arrow from "../../assets/icons/svg5.svg";
export default function Experience() {
  return (
    <div className="exerienceSection">
      <div className="left-e">
        <img src={mainImg} alt="" />
      </div>
      <div className="right-e">
        <h1>We provide you the best experience</h1>
        <p>
          You don't worry about the result because all of these interiors are
          made by people who are professionals in there fields with an elegant
          and luxurious style and with premium quality materials
        </p>
        <div className="numbers">
          <div className="div">
            <span>17</span>
            <span>Years experience</span>
          </div>
          <div>
            <span>85</span>
            <span>Awward gained</span>
          </div>
          <div>
            <span>537</span>
            <span>Furniture sold</span>
          </div>
        </div>
        <div className="buttonMore">
          <button>Learn More</button>
          <img src={arrow} alt="" />
        </div>
      </div>
    </div>
  );
}
