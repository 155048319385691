export const process = [
  {
    id: "01",
    title: "Briefing",
    text: "First thing you determine the concept of the model you want ,then chose the colors and the material you want to use in your funiture",
  },
  {
    id: "02",
    title: "Processing",
    text: "When the briefing process is completed and what the client wants has been archived. Then we carry out an execution to the desired concept",
  },
  {
    id: "03",
    title: "Finishing",
    text: "After the process is complete we will immediately carry out a finishing stage and we do it carefully and make sure there are no mistakes",
  },
];
