import "./App.css";
import Benefits from "./components/Benefits/Benefits";
import Experience from "./components/Experience/Experience";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Process from "./components/Process/Process";
import Review from "./components/Review/Review";

function App() {
  return (
    <div className="App">
      <Hero />
      <Benefits />
      <Experience />
      <Process />
      <Review />
      <Footer />
    </div>
  );
}

export default App;
