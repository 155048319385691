import React from "react";
import "./Footlinks.css";
import facebook from "../../assets/icons/logo-facebook.svg";
import instagram from "../../assets/icons/logo-instagram.svg";
import twitter from "../../assets/icons/logo-twitter.svg";
export default function Footlinks() {
  return (
    <div className="footlinksSection">
      <div>
        <h1 className="f-logo">Luxterior</h1>
        <h3>
          We help you to make an elegant and luxurious interior designed by
          professional interior designers
        </h3>
      </div>
      <div>
        <div className="about">
          <h1>About</h1>
          <a className="li" href="">
            About us
          </a>
          <a href="">Feature</a>
          <a href="">News</a>
          <a href="">Careers</a>
        </div>
        <div className="company">
          <h1>Company</h1>
          <a href="">Our team</a>
          <a href="">Partner with us</a>
          <a href="">FAQ</a>
          <a href="">Blog</a>
        </div>
        <div className="support">
          <h1>Support</h1>
          <a href="">Account</a>
          <a href="">Support center</a>
          <a href="">Feedback</a>
          <a href="">Contact Us</a>
          <a href="">Accesibility</a>
        </div>
        <div className="getintouch">
          <h1>Get in touch</h1>

          <p>Question or feedback? We'd love to here from you</p>

          <div>
            <img src={facebook} />
            <img src={twitter} />
            <img src={instagram} />
          </div>
        </div>
      </div>
    </div>
  );
}
