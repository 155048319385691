import React from "react";
import "./Navbar.css";
import searchLogo from "../../assets/icons/linked.svg";
import menuLogo from "../../assets/icons/menuIC.svg";

function Navbar() {
  return (
    <div className="container">
      <h1 className="logo">Luxterior</h1>
      <div className="links">
        <a href="" className="link">
          Home
        </a>
        <a href="" className="link">
          About
        </a>

        <a href="" className="link">
          Features
        </a>
        <a href="" className="link">
          Contacts
        </a>
      </div>
      <div>
        <img src={searchLogo} alt="" />
        <img src={menuLogo} alt="" />
      </div>
    </div>
  );
}
export default Navbar;
