import React from "react";
import "./Hero.css";
import heroImg from "../../assets/icons/herroimg.jpg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import chDown from "../../assets/icons/chevron-down.svg";
import chUp from "../../assets/icons/chevron-up.svg";
import Navbar from "../Navbar/Navbar";

function Hero() {
  return (
    <div className="heroSection">
      <Navbar />

      <div className="mainHero">
        <img src={heroImg} alt="" />
        {/* Arts */}
        <div className="artHolder">
          <div></div>
          <div>
            <img src={arrowDown} alt="" />
          </div>
        </div>
        {/* / Arts */}

        <div className="heroText">
          <span>We help you make modern interior</span>
          <span>
            We help you make an elegant and lugubrious interior designed by
            professional interior designers
          </span>
        </div>

        <div className="heroControls">
          <img src={chUp} alt="" />
          <img src={chDown} alt="" />
          <span className="counter">01</span>
          <div></div>
          <span>03</span>
        </div>
      </div>
    </div>
  );
}

export default Hero;
