import React from "react";
import "./Review.css";
import arrLeft from "../../assets/icons/arrow-back.svg";
import arrRight from "../../assets/icons/arrowri.svg";
import img from "../../assets/icons/herroimg.jpg";
import chat from "../../assets/icons/chatbubble-ellipses-outline.svg";
export default function Review() {
  return (
    <div className="reviewSection">
      <div>
        <span>
          We our customers <br></br>are saying
        </span>
        <div>
          <img src={arrLeft} />
          <img src={arrRight} />
        </div>
      </div>
      <div>
        <div className="cardsHolderr">
          <div className="cardd">
            <img src={chat} />
            <span>The result given are soo satisfying</span>
            <span className="textC">
              Being able to work with the exterior is an extraordinary thing{" "}
              they provide exelent services and the results are also very
              setisfying by giving elegant impresion
            </span>
            <div className="author">
              <img src={img} />
              <div className="details">
                <span>Ralph Edwards</span>
                <span>Product Manager</span>
              </div>
            </div>
          </div>

          {/* To  */}

          <div className="cardd">
            <img src={chat} />
            <span>The result given are soo satisfying</span>
            <span className="textC">
              Being able to work with the exterior is an extraordinary thing{" "}
              they provide exelent services and the results are also very
              setisfying by giving elegant impresion
            </span>
            <div className="author">
              <img src={img} />
              <div className="details">
                <span>Ralph Edwards</span>

                <span>Product Manager</span>
              </div>
            </div>
          </div>

          {/* to delete */}
        </div>
      </div>
    </div>
  );
}
