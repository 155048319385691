export const benefits = [
  {
    img: "./medal-outline.svg",
    title: "Best Quality",
    text: "All of our furniture uses the best material and choices for our customers",
  },
  {
    img: "./bag-handle-outline.svg",

    title: "Free Shipping",
    text: "Free shipping every time you buy furniture from us without delay",
  },
  {
    img: "./ribbon-outline.svg",

    title: "Warranty",
    text: "Every time you buy our furniture products you will get warranty without exceptions",
  },
];
