import React, { useEffect, useState } from "react";
import Footlinks from "../Footlinks/Footlinks";
import "./Footer.css";
export default function Footer() {
  const phone = window.innerWidth > 700 ? false : true;

  return (
    <div className="footerSection">
      <h1>
        Subscribe to get the latest news <br></br>about us
      </h1>
      <h2>
        We recommend you to subscribe to our newspaper, enter your email below
        to get our daily update about us
      </h2>
      <div className="emailHolder">
        <input
          placeholder={phone ? "Enter email " : "Enter your email address"}
        ></input>
        <button>Subscribe</button>
      </div>
      <div className="hackish"></div>
      <div>
        <Footlinks />
      </div>
    </div>
  );
}
