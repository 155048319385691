import React from "react";
import "./Benefits.css";
import { benefits } from "../../assets/jsons/benefitsData";
function Benefits() {
  return (
    <div className="benefitsSection">
      <span className="title">Benefits you get when using our services</span>

      <div className="benefits">
        {/* Div for data  */}

        {benefits.map((benefit, index) => (
          <div className="benefitItem" key={index}>
            <img src={benefit.img} alt="" />
            <div className="benefitItemText">
              <span>{benefit.title}</span>
              <span>{benefit.text}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Benefits;
